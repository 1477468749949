/*const parseKey = function parseKey(key) {
    return key.replace(/(_\w)/g, words => words[1].toUpperCase());
};

$.fn.extend({
    addressBook() {
        const element = this;
        const select = element.find('.address-book-select');
        const findByName = function findByName(name) {
            return element.find(`[name*=${parseKey(name)}]`);
        };

        select.dropdown({
            forceSelection: false,

            onChange(name, text, choice) {
                const { provinceCode, provinceName } = choice.data();
                const provinceContainer = select.parent().find('.province-container').get(0);

                element.find('input, select').each((index, input) => {
                    $(input).val('');
                });

                Object.entries(choice.data()).forEach(([property, value]) => {
                    const field = findByName(property);

                    if (property.indexOf('countryCode') !== -1) {
                        field.val(value).change();

                        const exists = setInterval(() => {
                            const provinceCodeField = findByName('provinceCode');
                            const provinceNameField = findByName('provinceName');

                            if (!provinceContainer.hasAttribute('data-loading')) {
                                if (provinceCodeField.length !== 0 && (provinceCode !== '' || provinceCode != undefined)) {
                                    provinceCodeField.val(provinceCode);

                                    clearInterval(exists);
                                } else if (provinceNameField.length !== 0 && (provinceName !== '' || provinceName != undefined)) {
                                    provinceNameField.val(provinceName);

                                    clearInterval(exists);
                                }
                            }
                        }, 100);
                    } else {
                        field.val(value);
                    }
                });
            },
        });
    },
});
*/



(function ($) {
    $.fn.delayedChange = function (options) {
        var timer;
        var o;

        if (jQuery.isFunction(options)) {
            o = { onChange: options };
        }
        else
            o = options;

        o = $.extend({}, $.fn.delayedChange.defaultOptions, o);

        return this.each(function () {
            var element = $(this);
            element.keyup(function () {
                clearTimeout(timer);
                timer = setTimeout(function () {
                    var newVal = element.val();
                    newVal = $.trim(newVal);
                    if (element.delayedChange.oldVal != newVal) {
                        element.delayedChange.oldVal = newVal;
                        o.onChange.call(this);
                    }

                }, o.delay);
            });
        });


    };

    $.fn.delayedChange.defaultOptions = {
        delay: 1000,
        onChange: function () { }
    }

    $.fn.delayedChange.oldVal = "";


})(jQuery);

$(document).ready(() => {

    $('#sylius-api-login-form').hide();
    $('#sylius_checkout_address_customer_email').delayedChange(function () {
        var url =  $('#sylius_checkout_address_customer_email').attr('data-url');
        var email = $('#sylius_checkout_address_customer_email').val();

        $.ajax({
            method: 'GET',
            dataType: 'json',
            url: url,
            data: {
                'email': email
            },
            beforeSend(settings) {
                const email = $('#sylius_checkout_address_customer_email').val();

                if (email.length < 3) {
                    return false;
                }

                settings.data = {
                    email,
                };

                return settings;
            },
            success: function(response) {
                if($('#sylius_checkout_address_customer_email').val() === response.username) {
                    console.log('success');
                    $('#sylius-api-login-form').show();
                }
            },
            error: function(xhr) {
                $('#sylius-api-login-form').hide();
            }
        });
    });



    const $element = $('#sylius-api-login');
    const passwordField = $element.find('input[type="password"]');
    const emailField = $element.find('input[type="email"]');
    const csrfTokenField = $element.find('input[type="hidden"]');
    const validationField = $element.find('#sylius-api-validation-error');

    $(document).on('click', '#sylius-api-login-submit', function() {
        var url = $(this).attr('data-url');
        $.ajax({
            method: 'POST',
            dataType: 'json',
            data : {
                _username: emailField.val(),
                _password: passwordField.val(),
                [csrfTokenField.attr('name')]: csrfTokenField.val(),
            },
            url : url,

            success: function(response) {
                window.location.reload();
             },

            error(response) {
                validationField.removeClass('hidden');
               // validationField.html(response.responseJSON.message);
            },
        });
    });


});


$(document).ready(() => {
    $(document).on('click', 'a.menu-header-xs', function (e) {
        $('div.main-menu').toggleClass('hidden-xs');
    });

});