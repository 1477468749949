/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you require will output into a single css file (app.css in this case)
require('../../public/theme/css/bootstrap.min.css');
require('../../public/theme/css/font-awesome.min.css');
require('../../public/theme/css/owl.carousel.css');
require('../../public/theme/css/owl.theme.css');
require('../../public/theme/css/owl.transitions.css');
require('../../public/theme/css/nivo-slider.css');

require('../../public/theme/css/animate.css');
require('../../public/theme/css//normalize.css');
require('../../public/theme/css/main.css');
require('../../public/theme/style.css');

require('../../public/theme/css/responsive.css');



//require('../../public/theme/js/main');
//require('modernizr');
require('bootstrap');
require('../../public/theme/js/owl.carousel.min.js');
//require('owl.carousel');
require('nivo-slider/jquery.nivo.slider.js');
require('nivo-slider/jquery.nivo.slider.pack.js');
require('wowjs');

require('../../public/theme/js/app.js');

require('../../public/theme/js/main.js');


 var $ = require('jquery');

global.$ = global.jQuery = $;
